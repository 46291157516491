// Allowed Actions
export const allowedActions = {
  ACH_PAYMENT: "ach_payment",
  APPLE_PAY: "apple_pay",
  AUTHORIZE_AUTOPAY: "authorize_autopay",
  BUTTON_CLICK: "button_click",
  CARD_PAYMENT: "card_payment",
  CONNECT_ACH_ACCOUNT: "connect_ach_account",
  DELETE_AUTOPAY: "delete_autopay",
  INITIALIZE: "initialize",
  IMPLEMENTATION_METHOD: "implementation_method",
  LOGIN_EXPRESS_PAY: "login_express_pay",
  LOGIN_REGISTERED: "login_registered",
  LOGOUT: "logout",
  NON_VALIDATION_REGISTRATION: "non_validation_registration",
  NON_VALIDATION_ADD_ACCOUNT: "non_validation_add_account",
  NON_VALIDATION_ADD_LOAN: "non_validation_add_loan",
  NON_VALIDATION_EDIT_ACCOUNT: "non_validation_edit_account",
  NON_VALIDATION_EDIT_LOAN: "non_validation_edit_loan",
  NON_VALIDATION_DELETE_LOAN: "non_validation_delete_loan",
  REGISTRATION: "registration",
  REGISTRATION_PROMOTION: "registration_promotion",
  UNIQUE_ID_LOOKUP: "unique_id_lookup",
  UPDATE_AUTOPAY: "update_autopay"
};

// Allowed Categories
export const allowedCategories = {
  ACH_PAYMENT_SELECT: "ach_payment_select",
  APPLE_PAY_SELECT: "apple_pay_select",
  ATTEMPT_ABANDONED: "attempt_abandoned",
  ATTEMPT_FAILURE: "attempt_failure",
  ACH_ACCOUNT: "ach_account",
  ATTEMPT_SUCCESS: "attempt_success",
  BREADCRUMBS_BACK: "breadcrumbs_back",
  BREADCRUMBS_FORWARD: "breadcrumbs_forward",
  CANCEL_AUTOPAY: "cancel_autopay",
  CARD_PAYMENT_SELECT: "card_payment_select",
  DEEP_LINK: "deep_link",
  DIRECT_LINK: "direct_link",
  EMBEDDED: "embedded",
  EXPRESS_PAY: "express_pay",
  MANAGE_AUTOPAY: "manage_autopay",
  NON_VALIDATION_ADD_ACCOUNT: "non_validation_add_account",
  PAY_NOW: "pay_now",
  REGISTERED: "registered",
  SETUP_AUTOPAY: "setup_autopay",
  SSO: "sso",
  UNKNOWN: "unknown"
};
